import React from 'react'
import {
  Card,
  CardBody,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'
import { graphql, Link } from 'gatsby'
import {
  Header,
  Layout,
  PublishedData,
  SinglePageContainer,
} from '../components/common'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img, { FluidObject } from 'gatsby-image'
import { ROUTES } from '../constants/routes'

interface BoardProps {
  data: {
    group: {
      color: string
      initials: string
      slug: string
    }
    posts: {
      totalCount: number
      nodes: [
        {
          caption: string
          createdAt: string
          id: string
          slug: string
          thumbnail?: {
            fluid: FluidObject
          }
          title: string
        }
      ]
    }
  }
  pageContext: {
    currentPage: number
    totalPages: number
  }
}

const Board: React.FC<BoardProps> = ({ data, pageContext }) => {
  const isFirst = pageContext.currentPage === 1
  const isLast = pageContext.currentPage === pageContext.totalPages
  const prevPageUrl =
    pageContext.currentPage === 2
      ? `/board/${data.group.slug}`
      : `/board/${data.group.slug}/${pageContext.currentPage - 1}`
  const nextPageUrl = `/board/${data.group.slug}/${pageContext.currentPage + 1}`

  const pagination = pageContext.totalPages > 1 && (
    <StyledPagination>
      <PaginationItem
        disabled={isFirst}
        title="Indietro"
        aria-disabled={isFirst}
      >
        {!isFirst ? (
          <Link to={prevPageUrl}>
            <PaginationLink previous />
          </Link>
        ) : (
          <PaginationLink previous />
        )}
      </PaginationItem>
      <PaginationItem disabled title="Attuale" aria-disabled="true">
        <PaginationLink>
          {pageContext.currentPage} di {pageContext.totalPages}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={isLast} title="Avanti" aria-disabled={isLast}>
        {!isLast ? (
          <Link to={nextPageUrl}>
            <PaginationLink next />
          </Link>
        ) : (
          <PaginationLink next />
        )}
      </PaginationItem>
    </StyledPagination>
  )

  return (
    <Layout navigation={{ location: ROUTES.BOARD, slug: data.group.slug }}>
      <Container>
        <Header
          color={data.group.color}
          title={`Bacheca ${data.group.initials}`}
        />
        <SinglePageContainer>
          {data.posts.totalCount === 0 ? (
            <EmptyMsgContainer>
              <EmptyMsg>
                Non ci sono ancora comunicazioni su questa bacheca
              </EmptyMsg>
              <FontAwesomeIcon icon="inbox" size="7x" />
            </EmptyMsgContainer>
          ) : (
            <CardContainer>
              <>
                {pagination}
                <Latest>Più recente</Latest>
                {data.posts.nodes.map(post => (
                  <PostCard key={post.id}>
                    <StyledLink to={`/news/${post.slug}`}>
                      <StyledCardBody>
                        <PublishedData createdAt={post.createdAt} />
                      </StyledCardBody>
                      {post.thumbnail && (
                        <StyledImg fluid={post.thumbnail.fluid} />
                      )}
                      <StyledCardBody>
                        <PostTitle>{post.title}</PostTitle>
                        <Caption>{post.caption}</Caption>
                      </StyledCardBody>
                    </StyledLink>
                  </PostCard>
                ))}
              </>
            </CardContainer>
          )}
          {pagination}
        </SinglePageContainer>
      </Container>
    </Layout>
  )
}

export default Board

export const pageQuery = graphql`
  query Board($groupId: String!, $limit: Int!, $skip: Int!) {
    group: contentfulGroup(id: { eq: $groupId }) {
      color
      initials
      slug
    }
    posts: allContentfulPost(
      filter: {
        groups: { elemMatch: { id: { eq: $groupId } } }
        slug: { ne: "dummy-post" }
      }
      limit: $limit
      skip: $skip
      sort: { fields: [createdAt], order: DESC }
    ) {
      totalCount
      nodes {
        id
        slug
        title
        caption
        createdAt
        thumbnail {
          fluid(maxHeight: 300, maxWidth: 800, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

const EmptyMsgContainer = styled.div`
  margin-top: 5rem;
  text-align: center;
`

const EmptyMsg = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 3rem;
`

const CardContainer = styled.div`
  margin: 3rem 0 2rem;

  @media only screen and (min-width: 1024px) {
    margin: 3rem 12rem;
  }
`

const Latest = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 1rem;
`

const StyledCardBody = styled(CardBody)`
  color: black;
  padding: 0;
`

const PostTitle = styled.div`
  font-size: 1.7rem;
  font-weight: bold;
`

const PostCard = styled(Card)`
  margin-bottom: 2rem;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding: 1.3rem;
`

const Caption = styled.div`
  color: lightslategray;
  font-family: sans-serif;
  font-size: 1.2rem;
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const StyledPagination = styled(Pagination)`
  color: var(--roma24-red);
  text-align: center;

  & > ul {
    display: inline-flex;
  }
`

const StyledImg = styled(Img)`
  margin: 1.5rem 0;
`
